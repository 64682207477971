import React from "react"
import { Layout, Title, ButtonPrimary, Banner, List, ListItem, AboutAuthor, AboutOrg, Awards, Award, FilmoviGlavniAnimator, MedunarodneIzlozbe, MedunarodnaIzlozba, FilmGlavniAnimator, Scenarij, Scenariji } from "../components/index"
import "../style/app.scss"
import { Link, graphql } from 'gatsby';
import Img from "gatsby-image"
import {Helmet} from 'react-helmet';
import Favicon from '../img/favicon3.png';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types' //for images i think

const knjige = [
  'Šest guba čarolija i jedan užas na kvadrat',
  'Bio sam pas i opet sam',
  'Usvojili smo baku i djeda',
  'Sve o evi - vodič za sanjare',
  'Bajka o šeširu koji je imao čovjeka',
  'Konradova prodavaonica ptica',
  'Grad na zrnu soli',
  'Riba koja nije umjela plakati',
  'Najhrabriji miš na svijetu',

];

const autori = [
  'Nena Lončar',
  'Marijan Lončar',
];

const crtaniFilmovi = [
  'Konradova prodavaonica ptica',
]



export const query = graphql`
  query {
    riba : file(relativePath: { eq: "riba-koja-nije-umjela-plakati.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mis : file(relativePath: { eq: "najhrabriji-miš-na-svijetu.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    grad : file(relativePath: { eq: "grad-na-zrnu-soli.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    eva : file(relativePath: { eq: "sve-o-evi-vodič-za-sanjare.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }    
  authors: allContentfulAuthor(limit: 3) {
    edges {
      node {
        name
        slug
        id
      }
    }
  }
  books: allContentfulBook(limit: 3) {
    edges {
      node {
        title
        slug
        id
      }
    }
  }
  films: allContentfulFilm {
    edges {
      node {
        name
        id
      }
    }
  }
  promotions: allContentfulPromotion(sort: {order: ASC, fields: createdAt}) {
      edges {
        node {
          id
          promoCode
          totalPrice
          subtitleTop
          subtitleBottom
          title
          books {
            availableForBuying
            price
            slug
            title
            cover {
              fluid {
                base64
                sizes
                src
                srcSet
                aspectRatio
              }
            }
          }
        }
      }
    }
  }
`

export default ({data}) => (
  <div className="home-page">
    <Helmet>
            <title>Umjetnička organizacija Kreativna Kuća – četiri sjajna naslova traže mjesto na vašoj polici!</title>
            <meta name='title' content='Umjetnička organizacija Kreativna Kuća – četiri sjajna naslova traže mjesto na vašoj polici!' />
            <meta name='description' content='KREATIVNA KUĆA je umjetnička organizacija osnovana 2018. godine koja se bavi objavljivanjem i distribucijom kvalitetnih književnih djela, izradom autorskih animiranih filmova i organiziranjem i provedbom kreativnih radionica i manifestacija vezanih za književnost i animirani film. Naša je misija povezivanje zbilje i mašte u originalne umjetničke izričaje i širenje prostora stvaralačke slobode. Osnivači i glavni provoditelji programa Umjetničke organizacije KREATIVNA KUĆA su višestruko nagrađivana književnica i scenaristica Nena Lončar i likovni umjetnik Marijan Lončar.' />
            <link rel='shortcut icon' type='image/x-icon' href={Favicon} />
            <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0' />
    </Helmet>
    <Layout>
      <Link to='/kontakt' className='banner-wrapper-link'>
        <Banner className='homepage-banner' title={data.promotions.edges[0].node.title} subtitleTop={data.promotions.edges[0].node.subtitleTop} subtitleBottom={data.promotions.edges[0].node.subtitleBottom} price={data.promotions.edges[0].node.totalPrice}>
          <div className="home-page-banner-imgs-wrapper">
            {data.promotions.edges[0].node.books.map( book => {
              return  <Img fluid={book.cover.fluid} alt={book.title} />
            })}
          </div>
        </Banner>
      </Link>
      <div className="homepage-lists-wrapper" style={{margin:'50px 20px'}}>
        <List title='Knjige' link='/knjige'>
              {
                    data.books.edges.map( edge => {
                        return (
                            <ListItem link={`/${edge.node.slug}`}>
                              {edge.node.title}
                            </ListItem>
                        );
                    })
                }
        </List>
        <List title='Autori' link='/autori'>
              {
                    data.authors.edges.map( edge => {
                        return (
                            <ListItem link={`/autori#${edge.node.slug}`}>
                              {edge.node.name}
                            </ListItem>
                        );
                    })
                }
        </List>
        <List className='anim-filmovi-list' title='Animirani Filmovi' link='/animirani-film'>
                {
                      data.films.edges.map( edge => {
                          return (
                            <ListItem link='/animirani-film'>
                              {edge.node.name}
                            </ListItem>
                          );
                      })
                  }
        </List>
      </div>

      <div>
      <Title title='Naruči knjigu' subtitle='Kontaktirajte autore i naručite knjigu!' />
        <div className="homepage-buy-button-wrapper">
        <Link to='/kontakt'><ButtonPrimary className='homepage-buy-button'>Naruči knjigu</ButtonPrimary></Link>
      </div>
        <div className="authors-wrapper">
        <AboutOrg title='Kreativna kuća'>
          <p>KREATIVNA KUĆA je umjetnička organizacija osnovana 2018. godine koja se bavi objavljivanjem i distribucijom kvalitetnih književnih djela, izradom autorskih animiranih filmova i organiziranjem i provedbom kreativnih radionica i manifestacija vezanih za književnost i animirani film. Naša je misija povezivanje zbilje i mašte u originalne umjetničke izričaje i širenje prostora stvaralačke slobode. Osnivači i glavni provoditelji programa Umjetničke organizacije KREATIVNA KUĆA su višestruko nagrađivana književnica i scenaristica Nena Lončar i likovni umjetnik Marijan Lončar.</p>
        </AboutOrg>
        <AboutAuthor className='author-nena' idd='nena-loncar' title='Nena Lončar'>
          <p>Scenaristica i višestruko nagrađivana književnica, rođena je 1968. godine u Krapini gdje i danas živi.</p>
          <p>Članica je nekih društava, ali ponajviše pripada svojoj obitelji i sebi samoj. Obožava čitati, a zapisivanjem  kroti razbarušene misli.</p>
          <p>Dok stvara priče, Nena se igra riječima. Jezik je za nju kao Svemir - čudesan i beskonačan. Maštom širi granice naših svjetova, a humorom se bori protiv predrasuda i gluposti.</p>
          <p>U svojem radu istražuje inovativne pristupe. Ni ludizam joj nije stran. Rado piše za djecu i uživa u tome.</p>
        </AboutAuthor>
        <Awards>
          <Award>nagrada <b>“Zvonko”</b> za roman <i>“Šest guba čarolija i jedan užas na kvadrat”</i></Award>
          <Award>nagrada <b>“Grigor Vitez”</b> za roman <i>“Sve o Evi”</i></Award>
          <Award>nagrada<b>“Zvonko”</b> za roman <i>“Bio sam pas i opet sam”</i></Award>
          <Award>međunarodna nagrada <b>“Mali princ”</b> za roman <i>“Sve o Evi”</i></Award>
        </Awards>
        <Scenariji>
          <Scenarij>Scenariji za <em>četiri epizode</em> animirane serije “<strong>Profesor Balthazar</strong>”, <em>2005.</em>
            <ul className='scenarij-sublist'>
              <li>”<strong>Mali problem</strong>”</li>
              <li><strong>”Sveopća praznina</strong>”</li>
              <li>”<strong>Kaktus Hombre</strong>”</li>
              <li>”<strong>Robot Spirit</strong>”</li>
            </ul>
          </Scenarij>
          <Scenarij>scenarij za <em>animirani film</em> ”<strong>Konradova prodavaonica ptica</strong>” (potpora <em>HAVC</em>-a), <em>2018.</em></Scenarij>
          <Scenarij>scenarij za <em>animirani film</em> “<strong>Tubist</strong>” (potpora <em>HAVC</em>-a), <em>2019.</em></Scenarij>
        </Scenariji>
        <AboutAuthor className='author-marijan' idd='marijan-loncar' title='Marijan Lončar'>
          <p>Marijan Lončar (1967) nagrađivani  je animator i autor animiranih filmova. </p>
          <p>Član je HZSU-a, HDFD-a i ASIFA-e.</p>
          <p>Tijekom godina, radio je kao glavni animator na mnogim filmovima od kojih su najznačajniji: Mali leteći medvjedići, Čudnovate zgode šegrta Hlapića, Profesor Baltazar,  Kontakt,  Špica za svjetski festival animiranog filma - Animafest.</p>
          <p>Ostvario je i nekoliko autorskih animiranih filmova: Sretan cvrčak, Banana, Božićna bajka.</p>
        </AboutAuthor>
        <FilmoviGlavniAnimator>
          <FilmGlavniAnimator>“Mali leteći medvjedići”</FilmGlavniAnimator>
          <FilmGlavniAnimator>“Čudnovate zgode Šegrta Hlapića”</FilmGlavniAnimator>
          <FilmGlavniAnimator>“Profesor Baltazar”</FilmGlavniAnimator>
          <FilmGlavniAnimator> “Kontakt”</FilmGlavniAnimator>
          <FilmGlavniAnimator>“Banana”</FilmGlavniAnimator>
          <FilmGlavniAnimator>“Špica za svjetski festival animiranog filma Animafest”</FilmGlavniAnimator>
        </FilmoviGlavniAnimator>
        <MedunarodneIzlozbe>
          <MedunarodnaIzlozba>Knjiga "Bajka o šeširu koji je imao čovjeka" je u sklopu međunarodnog natječaja ‘’Best Book Design from all over the World’’ izabrana za jednu od ‘’Hrvatskih lijepih knjiga’’ za 2017. godinu te je kao takva sudjelovala na izložbi u Leipzigu, a bit će i u stalnoj postavi izložbe ‘’Book Art International’’ koja se na jesen otvara u sklopu sajma knjiga u Frankfurtu.</MedunarodnaIzlozba>
          <MedunarodnaIzlozba>Izložba karikatura u “50th World Gallery of Cartoons Skopje 2018”</MedunarodnaIzlozba>
        </MedunarodneIzlozbe>
      </div>
      </div>
      
      <div style={{marginTop: '40px'}}>
      <Title title='Naruči knjigu' subtitle='Kontaktirajte autore i naručite knjigu!' />
        <div className="homepage-buy-button-wrapper">
        <Link to='/kontakt'><ButtonPrimary className='homepage-buy-button'>Naruči knjigu</ButtonPrimary></Link>
      </div>
      </div>
    </Layout>
  </div>
)
